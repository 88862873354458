<template>
  <div class="up-centers-wrapper">
    <header class="up-main-header">
      <h1 class="up-main-title">Учебные центры</h1>
      <el-input
        v-model="searchValue"
        prefix-icon="el-icon-search"
        placeholder="Поиск"
        @change="filterHandler"
      />
    </header>
    <template v-if="centersInPage.length > 0">
      <AppSort
        :sort-params="sortParams"
        :sort-by="sortBy"
        :sort-order="sortOrder"
        @sort="sortHandler($event, 'fetchCenters')"
      />
      <AppBlockAsTable class="up-centers-wrapper__items up-centers">
        <template slot="header">
          <!--p
            class="up-centers__number"
            :class="activeSortClass('number')"
            @click="sortHandler('number', 'fetchCenters')"
          >
            Номер <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p-->
          <p
            class="up-centers__name"
            :class="activeSortClass('name')"
            @click="sortHandler('name', 'fetchCenters')"
          >
            Наименование <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-centers__phone"
            :class="activeSortClass('phone')"
            @click="sortHandler('phone', 'fetchCenters')"
          >
            Телефон <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>

          <!--p class="up-centers__created">Создан</p-->
          <p
            class="up-centers__created"
            :class="activeSortClass('created')"
            @click="sortHandler('created', 'fetchCenters')"
          >
            Создан <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>

          <p class="up-centers__actions"></p>
        </template>
        <div v-for="c in centersInPage" :key="c.id" class="up-table__item">
          <!--p class="up-centers__number">
            <span class="is-pk-hidden">Номер:</span>{{ c.id.slice(0, 3) }}...
          </p-->
          <p class="up-centers__name">
            <span class="is-pk-hidden">Наименование:</span>{{ c.name }}
          </p>
          <p class="up-centers__phone">
            <span class="is-pk-hidden">Телефон:</span>{{ c.phone }}
          </p>
          <p class="up-centers__created">
            <span class="is-pk-hidden">Создан:</span>
            {{ c.created | ruDate("d.m.Y") }}
          </p>
          <p class="up-centers__actions">
            <el-dropdown
              @command="changeCenterHandler($event, c.id)"
              trigger="click"
            >
              <i class="el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">
                  <i class="el-icon-edit" /> редактировать
                </el-dropdown-item>
                <el-dropdown-item command="delete">
                  <i class="el-icon-delete" /> удалить
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </AppBlockAsTable>
    </template>
    <p v-else-if="!loading" class="up-empty-list-text">Список пуст</p>
    <footer class="up-centers-wrapper__footer">
      <router-link
        class="el-button el-button--primary"
        :to="`${$route.path}/new`"
      >
        <i class="el-icon-user" /> Новый УЦ
      </router-link>
      <el-pagination
        v-if="centers.length > perPage"
        background
        layout="prev, pager, next"
        :page-size="perPage"
        :current-page.sync="currentPage"
        :total="centers.length"
      />
    </footer>
    <AppSimpleModal
      :visible.sync="removeModalVisible"
      title="Удаление учебного центра"
      @action="deleteCenterHandler"
      @close="removeModalVisible = false"
    >
      Вы хотите удалить учебный центр. Выберите действие.
    </AppSimpleModal>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSimpleModal from "@/components/AppSimpleModal";
import AppSort from "@/components/AppSort";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";
import sortMixin from "@/mixins/sort.mixin";

import { mapActions } from "vuex";

export default {
  name: "EducationalCenters",
  mixins: [showErrorMessageMixin, sortMixin],
  components: { AppBlockAsTable, AppSimpleModal, AppSort },
  data: () => ({
    searchValue: "",
    loading: true,
    removeModalVisible: false,
    idOfRemovingCenter: null,
    centers: [],
    currentPage: 1,
    perPage: 15,
    sortParams: [
      {
        text: "Номеру",
        key: "number",
      },
      {
        text: "Имени",
        key: "name",
      },
      {
        text: "Телефону",
        key: "phone",
      },
    ],
  }),
  computed: {
    centersInPage() {
      const startNumber = (this.currentPage - 1) * this.perPage;

      return [...this.centers].slice(startNumber, startNumber + this.perPage);
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchCenters() {
      this.$nprogress.start();

      try {
        const { data: res } = await this.$axios.get("/admin/partners", {
          params: {
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
            q: this.searchValue,
          },
        });

        this.centers = res;
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    filterHandler() {
      this.currentPage = 1;
      this.fetchCenters();
    },
    changeCenterHandler(command, id) {
      this[`${command}Handler`](id);
    },
    deleteHandler(id) {
      this.removeModalVisible = true;
      this.idOfRemovingCenter = id;
    },
    editHandler(id) {
      this.$router.push(`/admin/educational-center/${id}`);
    },
    async deleteCenterHandler() {
      try {
        await this.$axios.delete(`/admin/partner/${this.idOfRemovingCenter}`);

        this.centers = this.centers.filter(
          (c) => c.id !== this.idOfRemovingCenter
        );

        this.removeModalVisible = false;

        this.$message({
          message: "Поздравляем! Учебный центр успешно удален.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Просмотр учебных центров");

    this.fetchCenters();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/educational-center/index.scss";
</style>